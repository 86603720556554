import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import loadable from "@loadable/component"
import ScrollAnimation from "react-animate-on-scroll"
import _ from "lodash"
// import NewsCard from "../NewsCard/NewsCard"
// import usePagination from "../../hooks/usePagination"
import "./assets/styles/_index.scss"
import CommunityCard from "../CommunityCard/CommunityCard"



const CommunityList = (props) => {
  const allCommunity = props?.data

  
  return (
    <section className="inner-tab-wrapper community-tab-wrapper">
        
      <div className="latest-community">
        <Container>
          <Row>
            <Col>
              {allCommunity.length > 0 ? (
                <div className="community-card-wrapper-main">
                  {allCommunity
                    ?.map(({ node }, index) => {
                      return (
                        <ScrollAnimation
                          animateIn="animate__slideInUp"
                          animateOnce
                          delay={100}
                          offset={10}
                        >
                          <CommunityCard data={node} tag="landing" />
                        </ScrollAnimation>
                      )
                    })}
                </div>
              ) : (
                <p>Sorry, noresults found, please check after sometimes</p>
              )}
            </Col>
            {/* <InnerPagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={filteredList.length}
              setCurrentPage={setCurrentPage}
            /> */}
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default CommunityList
