import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TileBlock from "../components/TileBlock/TileBlock"
import CommunityList from "../components/CommunityList/CommunityList"
import ListingIntroModule from "../components/ListingIntroModule/ListingIntroModule"


const Community = ({ data }, props) => {
  const PageData = data?.strapiPage
  const allNews = data?.allStrapiAreaGuide.edges
  // const elfData = data?.site.siteMetadata?.elfSight
  return (
    <Layout
      popularSearch={PageData?.select_popular_search?.title}
      customClass={
        PageData?.custom_css_classname ? PageData?.custom_css_classname : ""
      }
    >
      <div className="layout-padding-top">
        {/* <BreadcrumbModule
          parentname={PageData.choose_menu[0]?.strapi_parent?.title}
          parent={PageData.choose_menu[0]?.strapi_parent?.slug}
          pagename={PageData.title}
        /> */}

        <div className="grey-bg">
         
          {PageData?.add_page_modules?.length > 0 &&
            PageData.add_page_modules?.map((module, index) => {
              return (
                <>
                 {module.strapi_component === "page-modules.listing-module" &&
                    module.choose_list === "community" && (
                      <>
                        <ListingIntroModule
                          description={module.description}
                          headingTitle={
                            module.title ? module.title : PageData.title
                          }
                        />
                        <CommunityList data={allNews} />
                       
                      </>
                    )}
                 {module.strapi_component === "page-modules.tile-block" && (
                <TileBlock
                  {...module}
                  strapiID={PageData?.strapi_id}
                  ggfx_results={PageData?.ggfx_results}
                  imagetransforms={PageData?.imagetransforms?.internal?.content}
                />
              )}
                </>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
    />
  )
}

export default Community

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_TILE_BLOCK {
          ...TailBlockFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_LISTING_MODULE {
          ...ListingModuleFragment
        }
      }
    }
    allStrapiAreaGuide(
      filter: { publish: { eq: true } }
      sort: { fields: rank, order: DESC }
    ) {
      edges {
        node {
          title
          slug
          strapi_id
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          tile_image {
            url
          }
         
          
        }
      }
    }
  }
`
