import React from "react"
import { Link } from "gatsby"
import ImageModule from "../../modules/image-render"
import { PageLinks } from "../../common/site/page-static-links"
import "./assets/styles/_index.scss"
import GGFXImage from "../../modules/GGFXImage"
import { capitalizeWords } from "../../utils/common/utils"

const CommunityCard = (props) => {
  let cat_list = ""
  if (props.data?.category) {
    cat_list = capitalizeWords(props?.data?.category?.strapi_json_value)
  }
  const ImageRenderList = ({ item, imagename }) => {
    // let processedImages = JSON.stringify({});
    // if (item?.imagetransforms?.tile_image_Transforms) {
    //     processedImages = item?.imagetransforms?.tile_image_Transforms;
    // }

    return (
      <GGFXImage
        ImageSrc={item?.tile_image}
        key=""
        // altText={altText}
        altText={`${item.title}`}
        imagetransforms={item?.ggfx_results}
        renderer="srcSet"
        imagename="area-guide.tile_image.small_image"
        strapiID={item.strapi_id}
        className=""
      />
      // <ImageModule ImageSrc={item.tile_image} title={item.title} altText={item.title} imagetransforms={processedImages} renderer="srcSet" imagename={imagename} strapi_id={item.strapi_id} />
    )
  }

  return (
    <div className={`${props.tag == "landing" ? "" : "community-card-slide"}`}>
      <div className="community-card-wrapper">
        <div className="community-card-img-zoom">
          <a target="_blank" href={`/${PageLinks.community}/${props.data.slug}/`}>
            <ImageRenderList
              item={props?.data}
              imagename="blog.tile_image.small_image"
            />
          </a>
        </div>
        <div className="community-card-text-wrapper">
          <div className="community-card-cat">
            {String(cat_list).replace(",", ", ")}
          </div>
          <p className="community-card-title">
            <a target="_blank" href={`/${PageLinks.community}/${props.data.slug}/`}>
              {props.data.title}
            </a>
          </p>
          {/* <div className="community-card-date">{props.data.date}</div> */}
        </div>
      </div>
    </div>
  )
}

export default CommunityCard
